import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Assuming this is the API call you have to get the school ID based on the roll number
import { findSchoolByRollNumber } from "../services/APIClient"; // Adjust the import based on your API structure

const SchoolRollNumberInput = ({ onSchoolIdRetrieved, schoolNameRetrieved }) => {
  const [rollNumber, setRollNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    console.log("Input changed:", e.target.value);
    setRollNumber(e.target.value);
  };

  const handleSubmit = async () => {
    console.log("Form submitted with rollNumber:", rollNumber);

    if (!rollNumber) {
      console.log("No roll number provided.");
      toast.error("Please enter a valid roll number.");
      return;
    }

    setLoading(true);
    console.log("Loading state set to true.");

    try {
      console.log("Calling findSchoolByRollNumber API with rollNumber:", rollNumber);
      const school = await findSchoolByRollNumber(rollNumber); // Assuming this returns the school object with the ID
      console.log("API call successful, response:", school);

      if (school && school.id) {
        console.log("School found, school ID:", school.id);
        toast.success("School found successfully!");
        onSchoolIdRetrieved(school.id); // Pass the school ID back to the parent component
        schoolNameRetrieved(school.name);
      } else {
        console.log("No school found with this roll number.");
        toast.error("No school found with this roll number.");
      }
    } catch (error) {
      console.error("Error fetching school:", error);
      toast.error("Error fetching the school. Please try again.");
    } finally {
      console.log("Loading state set to false.");
      setLoading(false);
    }
  };

  return (
    <div className="school-rollnumber-input-container">
      <ToastContainer position="top-center" autoClose={3000} />
      <Form.Group>
        <Form.Label>Insert School Identifier (Rollnumber/URN)</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter roll number or URN"
          value={rollNumber}
          onChange={handleInputChange}
          disabled={loading}
        />
      </Form.Group>
      <Button
        variant="primary"
        onClick={handleSubmit}
        disabled={loading || !rollNumber}
      >
        {loading ? "Loading..." : "Find School"}
      </Button>
    </div>
  );
};

export default SchoolRollNumberInput;
