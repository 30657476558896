import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import OrientationPrompt from "../components/OrientationPrompt";

function ResettingTasks() {
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );
  const [name, setName] = useState("User"); // Placeholder name for the HeadingBanner

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    // You can fetch the user data here if needed to set the name
    // For now, it's hardcoded as "User"
    setName("User");
  }, []);

  return (
    <div>
      <Container fluid className="vh-100">
        {/* HeadingBanner with placeholder name */}
        <HeadingBanner name={`Welcome ${name}!`} isLoggedIn={true} cloud={true} />
        
        <Row className="h-100">
          {/* LeftPanel */}
          <LeftPanel />
          
          {/* Main content */}
          <Col sm={{ span: 10, offset: 2 }} className="blue-background d-flex justify-content-center align-items-center">
            <h1>Coming Soon</h1>
          </Col>
        </Row>
      </Container>

      {/* OrientationPrompt for portrait mode */}
      {isPortrait && <OrientationPrompt />}
    </div>
  );
}

export default ResettingTasks;
