import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { getTeachersBySchoolId, softDeleteTeacher } from "../services/APIClient"; // Import your API calls

function RemovingTeacherModal({ show, onHide, schoolid }) {
  const [teachers, setTeachers] = useState([]); // Store teachers
  const [selectedTeacher, setSelectedTeacher] = useState(null); // Store the selected teacher
  const [confirmationVisible, setConfirmationVisible] = useState(false); // Toggle for confirmation
  const [statusMessage, setStatusMessage] = useState(""); // Status message for success/failure
  const [alertVariant, setAlertVariant] = useState(""); // Alert variant for Bootstrap

  // Fetch teachers when the modal opens
  useEffect(() => {
    if (schoolid) {
      getTeachersBySchoolId(schoolid)
        .then((response) => {
          setTeachers(response); // Store the teachers
        })
        .catch((error) => {
          setStatusMessage("Error fetching teachers. Please try again.");
          setAlertVariant("danger");
        });
    }
  }, [schoolid]);

  // Handle teacher removal confirmation
  const handleRemoveTeacher = () => {
    setConfirmationVisible(true); // Show the confirmation step
  };

  // Handle the actual delete API call after confirmation
  const confirmRemoveTeacher = () => {
    if (selectedTeacher) {
      softDeleteTeacher(selectedTeacher)
        .then(() => {
          const teacherName = teachers.find(t => t.id === selectedTeacher).name;
          setStatusMessage(`Teacher "${teacherName}" was removed successfully!`);
          setAlertVariant("success");
          clearFields(); // Clear selection and reset the form
          autoClearMessage(); // Clear the message after a few seconds
        })
        .catch((error) => {
          setStatusMessage("Failed to remove the teacher. Please try again.");
          setAlertVariant("danger");
        })
        .finally(() => {
          setConfirmationVisible(false); // Hide confirmation
        });
    }
  };

  // Automatically clear the status message after a few seconds
  const autoClearMessage = () => {
    setTimeout(() => {
      setStatusMessage("");
      setAlertVariant("");
    }, 5000); // Clear message after 5 seconds
  };

  // Clear the modal fields and messages
  const clearFields = () => {
    setSelectedTeacher(null);
  };

  return (
    <Modal show={show} onHide={() => {
      onHide();
      clearFields(); // Clear the fields when modal is closed
      setConfirmationVisible(false); // Hide confirmation when modal closes
    }}>
      <Modal.Header closeButton>
        <Modal.Title>Removing Teacher from School</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Dropdown for selecting a teacher */}
        <Form.Group>
          <Form.Label>Select Teacher to Remove:</Form.Label>
          <Form.Control
            as="select"
            value={selectedTeacher || ""}
            onChange={(e) => setSelectedTeacher(e.target.value)}
          >
            <option value="">Select Teacher</option>
            {teachers.map((teacher) => (
              <option key={teacher.id} value={teacher.id}>
                {teacher.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        {/* Remove button with teacher name */}
        {selectedTeacher && (
          <Button variant="danger" className="mt-3" onClick={handleRemoveTeacher}>
            Remove {teachers.find(t => t.id === selectedTeacher)?.name}
          </Button>
        )}

        {/* Confirmation message */}
        {confirmationVisible && (
          <div className="mt-3">
            <Alert variant="warning">
              Are you sure you want to remove teacher "
              {teachers.find(t => t.id === selectedTeacher)?.name}"? This action cannot be undone.
            </Alert>
            <Button variant="danger" onClick={confirmRemoveTeacher}>
              Confirm Remove
            </Button>
            <Button variant="secondary" className="ml-2" onClick={() => setConfirmationVisible(false)}>
              Cancel
            </Button>
          </div>
        )}

        {/* Status message for success or failure */}
        {statusMessage && (
          <Alert variant={alertVariant} className="mt-3" dismissible onClose={() => setStatusMessage("")}>
            {statusMessage}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {
          onHide();
          clearFields(); // Clear fields when modal is closed
          setStatusMessage(""); // Clear any existing messages when modal is closed
        }}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RemovingTeacherModal;
