import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { getClassesBySchoolId, getStudentsByClassId, updateStudentClassId } from "../services/APIClient"; // Import your API calls

function StudentTransfersModal({ show, onHide, schoolid }) {
  const [classes, setClasses] = useState([]); // Store classes
  const [students, setStudents] = useState([]); // Store students for the selected class
  const [selectedOldClass, setSelectedOldClass] = useState(null); // Store the selected old class
  const [selectedStudent, setSelectedStudent] = useState(null); // Store the selected student
  const [selectedNewClass, setSelectedNewClass] = useState(null); // Store the new class for transfer
  const [statusMessage, setStatusMessage] = useState(""); // Status message for success/failure
  const [alertVariant, setAlertVariant] = useState(""); // Alert variant for Bootstrap

  // Fetch classes when the modal opens
  useEffect(() => {
    if (schoolid) {
      getClassesBySchoolId(schoolid)
        .then((response) => {
          setClasses(response); // Store the classes
        })
        .catch((error) => {
          setStatusMessage("Error fetching classes. Please try again.");
          setAlertVariant("danger");
        });
    }
  }, [schoolid]);

  // Fetch students when a class is selected
  useEffect(() => {
    if (selectedOldClass) {
      getStudentsByClassId(selectedOldClass)
        .then((response) => {
          setStudents(response); // Store the students for the selected class
        })
        .catch((error) => {
          setStatusMessage("Error fetching students. Please try again.");
          setAlertVariant("danger");
        });
    }
  }, [selectedOldClass]);

  // Handle student transfer
  const handleTransferStudent = () => {
    if (selectedStudent && selectedNewClass) {
      updateStudentClassId(selectedStudent, selectedNewClass)
        .then(() => {
          const studentName = students.find(s => s.id === selectedStudent).name;
          const oldClassName = classes.find(cls => cls.id === selectedOldClass)?.name;
          const newClassName = classes.find(cls => cls.id === selectedNewClass)?.name;
          setStatusMessage(`Student "${studentName}" has been transferred from "${oldClassName}" to "${newClassName}".`);
          setAlertVariant("success");
          clearFields(); // Clear selection and reset the form
          autoClearMessage(); // Clear the message after a few seconds
        })
        .catch((error) => {
          setStatusMessage("Failed to transfer the student. Please try again.");
          setAlertVariant("danger");
        });
    } else {
      setStatusMessage("Please select both a student and a new class.");
      setAlertVariant("warning");
    }
  };

  // Automatically clear the status message after a few seconds
  const autoClearMessage = () => {
    setTimeout(() => {
      setStatusMessage("");
      setAlertVariant("");
    }, 5000); // Clear message after 5 seconds
  };

  // Clear the modal fields and messages
  const clearFields = () => {
    setSelectedOldClass(null);
    setSelectedStudent(null);
    setSelectedNewClass(null);
    setStudents([]); // Clear the student list when class changes
  };

  return (
    <Modal show={show} onHide={() => {
      onHide();
      clearFields(); // Clear the fields when modal is closed
    }}>
      <Modal.Header closeButton>
        <Modal.Title>Student Transfers Between Classes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Dropdown for selecting old class */}
        <Form.Group>
          <Form.Label>Select Current Class:</Form.Label>
          <Form.Control
            as="select"
            value={selectedOldClass || ""}
            onChange={(e) => setSelectedOldClass(e.target.value)}
          >
            <option value="">Select Class</option>
            {classes.map((cls) => (
              <option key={cls.id} value={cls.id}>
                {cls.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        {/* Dropdown for selecting a student */}
        {selectedOldClass && (
          <Form.Group className="mt-3">
            <Form.Label>Select Student From Class:</Form.Label>
            <Form.Control
              as="select"
              value={selectedStudent || ""}
              onChange={(e) => setSelectedStudent(e.target.value)}
            >
              <option value="">Select Student</option>
              {students.map((student) => (
                <option key={student.id} value={student.id}>
                  {student.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}

        {/* Dropdown for selecting the new class */}
        {selectedStudent && (
          <Form.Group className="mt-3">
            <Form.Label>Select New Class:</Form.Label>
            <Form.Control
              as="select"
              value={selectedNewClass || ""}
              onChange={(e) => setSelectedNewClass(e.target.value)}
            >
              <option value="">Select Class</option>
              {classes.map((cls) => (
                <option key={cls.id} value={cls.id}>
                  {cls.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}

        {/* Transfer button */}
        {selectedNewClass && (
          <Button variant="primary" className="mt-3" onClick={handleTransferStudent}>
            Transfer Student
          </Button>
        )}

        {/* Status message for success or failure */}
        {statusMessage && (
          <Alert variant={alertVariant} className="mt-3" dismissible onClose={() => setStatusMessage("")}>
            {statusMessage}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {
          onHide();
          clearFields(); // Clear fields when modal is closed
          setStatusMessage(""); // Clear any existing messages when modal is closed
        }}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default StudentTransfersModal;
