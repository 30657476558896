import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap"; // Import Alert from Bootstrap
import { getClassesBySchoolId, getStudentsByClassId, getAssessmentsByStudentId } from "../services/APIClient"; // Import your API calls

function SwapStudentsAssessmentModal({ show, onHide, schoolid, swapAssessmentStudents }) {
  const [classes, setClasses] = useState([]); // Store classes
  const [studentsA, setStudentsA] = useState([]); // Store students for Student A
  const [studentsB, setStudentsB] = useState([]); // Store students for Student B
  const [assessmentsA, setAssessmentsA] = useState([]); // Store assessments for Student A
  const [assessmentsB, setAssessmentsB] = useState([]); // Store assessments for Student B

  const [selectedClassA, setSelectedClassA] = useState(null); // Selected class for Student A
  const [selectedStudentA, setSelectedStudentA] = useState(null); // Selected student for Student A
  const [selectedAssessmentA, setSelectedAssessmentA] = useState(null); // Selected assessment for Student A

  const [selectedClassB, setSelectedClassB] = useState(null); // Selected class for Student B
  const [selectedStudentB, setSelectedStudentB] = useState(null); // Selected student for Student B
  const [selectedAssessmentB, setSelectedAssessmentB] = useState(null); // Selected assessment for Student B

  const [statusMessage, setStatusMessage] = useState(""); // Status message for success/failure
  const [alertVariant, setAlertVariant] = useState(""); // Alert variant for Bootstrap

  // Fetch classes when the modal opens
  useEffect(() => {
    if (schoolid) {
      getClassesBySchoolId(schoolid)
        .then((response) => {
          setClasses(response); // Store the classes
        })
        .catch((error) => {
          console.error("Error fetching classes:", error);
        });
    }
  }, [schoolid]);

  // Fetch students when a class is selected for Student A
  useEffect(() => {
    if (selectedClassA) {
      getStudentsByClassId(selectedClassA)
        .then((response) => {
          setStudentsA(response); // Store students for Student A
        })
        .catch((error) => {
          console.error("Error fetching students for Student A:", error);
        });
    }
  }, [selectedClassA]);

  // Fetch students when a class is selected for Student B
  useEffect(() => {
    if (selectedClassB) {
      getStudentsByClassId(selectedClassB)
        .then((response) => {
          setStudentsB(response); // Store students for Student B
        })
        .catch((error) => {
          console.error("Error fetching students for Student B:", error);
        });
    }
  }, [selectedClassB]);

  // Fetch assessments for Student A when a student is selected
  useEffect(() => {
    if (selectedStudentA) {
      getAssessmentsByStudentId(selectedStudentA)
        .then((response) => {
          setAssessmentsA(response); // Store assessments for Student A
        })
        .catch((error) => {
          console.error("Error fetching assessments for Student A:", error);
        });
    }
  }, [selectedStudentA]);

  // Fetch assessments for Student B when a student is selected
  useEffect(() => {
    if (selectedStudentB) {
      getAssessmentsByStudentId(selectedStudentB)
        .then((response) => {
          setAssessmentsB(response); // Store assessments for Student B
        })
        .catch((error) => {
          console.error("Error fetching assessments for Student B:", error);
        });
    }
  }, [selectedStudentB]);

  const handleSwapAssessments = () => {
    if (selectedAssessmentA && selectedAssessmentB) {
      swapAssessmentStudents(selectedAssessmentA, selectedAssessmentB)
        .then(() => {
          setStatusMessage("Swap was successful!");
          setAlertVariant("success"); // Set Bootstrap alert variant to 'success'
          clearFields(); // Clear the fields
        })
        .catch(() => {
          setStatusMessage("Swap was unsuccessful. Please try again.");
          setAlertVariant("danger"); // Set Bootstrap alert variant to 'danger' for error
        });
    } else {
      setStatusMessage("Please select assessments for both students.");
      setAlertVariant("warning"); // Set Bootstrap alert variant to 'warning' for missing info
    }
  };

  // Clear form fields
  const clearFields = () => {
    setSelectedClassA(null);
    setSelectedStudentA(null);
    setSelectedAssessmentA(null);
    setStudentsA([]);
    setAssessmentsA([]);

    setSelectedClassB(null);
    setSelectedStudentB(null);
    setSelectedAssessmentB(null);
    setStudentsB([]);
    setAssessmentsB([]);
  };

  return (
    <Modal show={show} onHide={() => {
      onHide();
      setStatusMessage(""); // Clear message when modal is closed
      setAlertVariant(""); // Clear alert variant when modal is closed
    }}>
      <Modal.Header closeButton>
        <Modal.Title>Swapping Students Assessments</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="swap-assessments-container">
          {/* Student A Section */}
          <div className="student-section">
            <h5>Student A</h5>
            <Form.Group>
              <Form.Label>Class:</Form.Label>
              <Form.Control
                as="select"
                value={selectedClassA || ""}
                onChange={(e) => setSelectedClassA(e.target.value)}
              >
                <option value="">Select Class</option>
                {classes.map((cls) => (
                  <option key={cls.id} value={cls.id}>
                    {cls.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Student:</Form.Label>
              <Form.Control
                as="select"
                value={selectedStudentA || ""}
                onChange={(e) => setSelectedStudentA(e.target.value)}
              >
                <option value="">Select Student</option>
                {studentsA.map((student) => (
                  <option key={student.id} value={student.id}>
                    {student.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Assessment:</Form.Label>
              <Form.Control
                as="select"
                value={selectedAssessmentA || ""}
                onChange={(e) => setSelectedAssessmentA(e.target.value)}
              >
                <option value="">Select Assessment</option>
                {assessmentsA.map((assessment) => (
                  <option key={assessment.id} value={assessment.id}>
                    {assessment.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>

          {/* Student B Section */}
          <div className="student-section">
            <h5>Student B</h5>
            <Form.Group>
              <Form.Label>Class:</Form.Label>
              <Form.Control
                as="select"
                value={selectedClassB || ""}
                onChange={(e) => setSelectedClassB(e.target.value)}
              >
                <option value="">Select Class</option>
                {classes.map((cls) => (
                  <option key={cls.id} value={cls.id}>
                    {cls.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Student:</Form.Label>
              <Form.Control
                as="select"
                value={selectedStudentB || ""}
                onChange={(e) => setSelectedStudentB(e.target.value)}
              >
                <option value="">Select Student</option>
                {studentsB.map((student) => (
                  <option key={student.id} value={student.id}>
                    {student.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Assessment:</Form.Label>
              <Form.Control
                as="select"
                value={selectedAssessmentB || ""}
                onChange={(e) => setSelectedAssessmentB(e.target.value)}
              >
                <option value="">Select Assessment</option>
                {assessmentsB.map((assessment) => (
                  <option key={assessment.id} value={assessment.id}>
                    {assessment.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        </div>

        {/* Bootstrap Alert for success/failure messages */}
        {statusMessage && (
          <Alert variant={alertVariant} className="mt-3">
            {statusMessage}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {
          onHide();
          setStatusMessage(""); // Clear message when modal is closed
          setAlertVariant(""); // Clear alert variant when modal is closed
        }}>
          Close
        </Button>
        <Button variant="success" onClick={handleSwapAssessments}>
          Swap Assessments
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SwapStudentsAssessmentModal;
