import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import {
  getAlpacaLeadChangeReasonOptions,
  updateAlpacaLeadChangeReason,
} from "../services/APIClient";
import { addAlpacaLeadRole, removeAlpacaLeadRole } from "../services/APIClient";
import { toast } from "react-toastify";

function AlpacaLeadChangeModal({
  teachers,
  currentAlpacaLead,
  schoolid,
  onAlpacaLeadChange,
}) {
  const [show, setShow] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [reasonOptions, setReasonOptions] = useState([]);

  const handleClose = () => {
    setShow(false);
    setSelectedTeacher("");
    setSelectedReason("");
    setOtherReason("");
  };

  const handleShow = () => setShow(true);

  const notifySuccess = (msg) => toast.success(msg);
  const notifyError = (msg) => toast.error(msg);

  const handleSave = async () => {
    if (selectedTeacher && selectedReason) {
      if (
        selectedReason ===
          reasonOptions.find((option) => option.reason === "Other")?.id &&
        !otherReason
      ) {
        notifyError("Please provide a reason for the change.");
        return;
      }

      try {
        // Remove current Alpaca Lead role if it exists
        if (currentAlpacaLead && currentAlpacaLead.id) {
          await removeAlpacaLeadRole(schoolid, currentAlpacaLead.id);
        }

        // Add new Alpaca Lead role
        await addAlpacaLeadRole(schoolid, selectedTeacher);

        // Create Alpaca Lead change history record
        const alpacaLeadChangeData = {
          teacherId: selectedTeacher,
          oldAlpacaLead: currentAlpacaLead?.id || "None",
          newAlpacaLead: selectedTeacher,
          reasonForChangeId: selectedReason,
          alpacaleadchangereason:
            selectedReason ===
            reasonOptions.find((option) => option.reason === "Other")?.id
              ? otherReason
              : reasonOptions.find((option) => option.id === selectedReason)
                  ?.reason,
          dateChanged: new Date().toISOString(),
        };

        await updateAlpacaLeadChangeReason(alpacaLeadChangeData);

        // Notify parent component about the change
        onAlpacaLeadChange(selectedTeacher);

        notifySuccess("Alpaca Lead updated successfully!");
        handleClose();
      } catch (error) {
        console.error("Error updating Alpaca Lead:", error);
        notifyError("Failed to update Alpaca Lead. Please try again.");
      }
    } else {
      notifyError("Please select a teacher and a reason for the change.");
    }
  };

  useEffect(() => {
    const fetchReasonOptions = async () => {
      try {
        const reasons = await getAlpacaLeadChangeReasonOptions();
        setReasonOptions(reasons);
      } catch (error) {
        console.error("Error fetching Alpaca Lead Change Reasons:", error);
        notifyError(
          "Failed to fetch Alpaca Lead Change reasons. Please try again."
        );
      }
    };

    fetchReasonOptions();
  }, []);

  const handleReasonChange = (e) => {
    setSelectedReason(e.target.value);
    if (
      e.target.value !==
      reasonOptions.find((option) => option.reason === "Other")?.id
    ) {
      setOtherReason("");
    }
  };

  const handleOtherReasonChange = (e) => {
    setOtherReason(e.target.value);
  };

  return (
    <>
      <button className="blue-button" onClick={handleShow}>
        Change ALPACA Lead
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change ALPACA Lead</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="selectTeacher">
            <Form.Label>Select a new ALPACA Lead Teacher:</Form.Label>
            <Form.Control
              as="select"
              value={selectedTeacher}
              onChange={(e) => setSelectedTeacher(e.target.value)}
            >
              <option value="" disabled>
                Select a teacher
              </option>
              {teachers?.map((teacher) => (
                <option key={teacher.id} value={teacher.id}>
                  {teacher.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="selectReason">
            <Form.Label>Select a reason for the change:</Form.Label>
            <Form.Control
              as="select"
              value={selectedReason}
              onChange={handleReasonChange}
            >
              <option value="" disabled>
                Select a reason
              </option>
              {reasonOptions?.map((reason) => (
                <option key={reason.id} value={reason.id}>
                  {reason.reason}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {selectedReason ===
            reasonOptions.find((option) => option.reason === "Other")?.id && (
            <Form.Group className="mt-3">
              <Form.Label>
                <strong>Other Reason</strong>
              </Form.Label>
              <Form.Control
                type="text"
                value={otherReason}
                onChange={handleOtherReasonChange}
                style={{ width: "100%" }}
              />
            </Form.Group>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="white-button" onClick={handleClose}>
            Close
          </button>
          <button className="blue-button" onClick={handleSave}>
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AlpacaLeadChangeModal;
