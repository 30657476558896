import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import UserExistenceOverview from "../components/UserExistenceOverview";
import SchoolRollNumberInput from "../components/SchoolRollNumberInput";
import OrientationPrompt from "../components/OrientationPrompt";

import {
  whoami,
  getClasses,
  getTeachersBySchoolId,
  getSchools,
  getCountryGrades,
} from "../services/APIClient";

import "../components/ClassCard.css";

function UserExistence() {
  const [schoolid, setSchoolid] = useState(null);  // The school ID we will set from the input component
  const [schoolName, setSchoolName] = useState(null);  // The school ID we will set from the input component
  const [teacherid, setTeacherid] = useState(null); 
  const [name, setName] = useState("");
  const [teachers, setTeachers] = useState(null);  // Teachers fetched based on schoolid
  const [rollNumber, setRollNumber] = useState(null); 
  const [teacherEmail, setTeacherEmail] = useState(null); 
  const [classes, setClasses] = useState([]); 
  const [gradeLevelOptions, setGradeLevelOptions] = useState([]); 
  const [countryId, setCountryId] = useState([]);

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    console.log("Checking initial screen orientation:", isPortrait);
    const handleOrientationChange = () => {
      console.log("Screen orientation changed:", window.innerWidth < window.innerHeight);
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  const [loading, setLoading] = useState(false);

  // Get current user information (this is just to display user-related data like teacherEmail)
  useEffect(() => {
    console.log("Fetching current user info...");
    whoami().then((response) => {
      console.log("whoami() response:", response);
      setTeacherid(response.data.stakeholder.id);
      setName(response.data.stakeholder.name);
      setTeacherEmail(response.data.email);
    }).catch(error => {
      console.error("Error fetching user info (whoami):", error);
    });
  }, []);

  // Fetch teachers, classes, and school data when school ID from input changes
  useEffect(() => {
    async function fetchSchoolData() {
      if (!schoolid) {
        console.log("No schoolid provided yet.");
        return;
      }

      try {
        setLoading(true);
        console.log("Fetching school data for school ID:", schoolid);

        const teachersResponse = await getTeachersBySchoolId(schoolid);
        console.log("Teachers response for school ID:", schoolid, teachersResponse);
        setTeachers(teachersResponse);

        const classesResponse = await getClasses();
        console.log("Classes response:", classesResponse);
        classesResponse.sort(
          (a, b) => new Date(b.date_created) - new Date(a.date_created)
        );
        setClasses(classesResponse);

        const schoolsResponse = await getSchools();
        console.log("Schools response:", schoolsResponse);
        const matchingSchool = schoolsResponse.data.find(
          (school) => school.id === schoolid
        );

        if (matchingSchool) {
          console.log("Matching school found:", matchingSchool);
          const rollNumber = matchingSchool.rollnumber;
          const countryid = matchingSchool.countryid;
          setCountryId(countryid);
          setRollNumber(rollNumber);

          // Fetch country-specific grades
          getCountryGrades(countryid)
            .then((gradesResponse) => {
              console.log("Country grades response:", gradesResponse);
              setGradeLevelOptions([gradesResponse]);
            })
            .catch((error) => {
              console.error("Error fetching country grades:", error);
            });
        } else {
          console.log("No matching school found with school ID:", schoolid);
        }
      } catch (error) {
        console.error("Error fetching school data:", error);
      } finally {
        setLoading(false);
        console.log("Data fetching complete.");
      }
    }

    // Fetch school data only after the `schoolid` from input is set
    if (schoolid) {
      console.log("schoolid from input is set, fetching data...");
      fetchSchoolData();
    }
  }, [schoolid]);  // Now this effect will run whenever schoolid from input changes

  console.log(schoolid)
  console.log(teachers)

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={"User Existence"}
        isLoggedIn={true}
        cloud={true}
        teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">

          {/* School Roll Number Input component */}
          <SchoolRollNumberInput
            onSchoolIdRetrieved={setSchoolid} // Set school ID from input
            schoolNameRetrieved={setSchoolName} // Set school ID from input
          />

          {loading ? (
            <div className="spinner-container">
              <div className="big-ball-spinner"></div>
            </div>
          ) : (
            <div className="row-position">
              <UserExistenceOverview
                teachers={teachers}  // Now teachers are fetched based on schoolid from input
                schoolid={schoolid}
                schoolName={schoolName}
                name={name}
                rollNumber={rollNumber}
                teacherEmail={teacherEmail}
                countryId={countryId}
              />
            </div>
          )}
        </Col>
      </Row>
      {isPortrait && <OrientationPrompt />}
    </Container>
  );
}

export default UserExistence;
