import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { getClassesBySchoolId, softDeleteClass } from "../services/APIClient"; // Import your API calls

function DeleteClassesModal({ show, onHide, schoolid }) {
  const [classes, setClasses] = useState([]); // Store classes
  const [selectedClass, setSelectedClass] = useState(null); // Store the selected class
  const [statusMessage, setStatusMessage] = useState(""); // Status message for success/failure
  const [alertVariant, setAlertVariant] = useState(""); // Alert variant for Bootstrap
  const [confirmationVisible, setConfirmationVisible] = useState(false); // Toggle for confirmation

  // Fetch classes when the modal opens
  useEffect(() => {
    if (schoolid) {
      getClassesBySchoolId(schoolid)
        .then((response) => {
          setClasses(response); // Store the classes
        })
        .catch((error) => {
          console.error("Error fetching classes:", error);
        });
    }
  }, [schoolid]);

  // Handle class deletion confirmation
  const handleDeleteClass = () => {
    setConfirmationVisible(true); // Show the confirmation step
  };

  // Handle the actual delete API call after confirmation
  const confirmDeleteClass = () => {
    if (selectedClass) {
      softDeleteClass(selectedClass)
        .then(() => {
          setStatusMessage(`Class "${classes.find(c => c.id === selectedClass).name}" was deleted successfully!`);
          setAlertVariant("success");
          clearFields(); // Clear selection and reset the form
        })
        .catch((error) => {
          setStatusMessage("Failed to delete the class. Please try again.");
          setAlertVariant("danger");
        })
        .finally(() => {
          setConfirmationVisible(false); // Hide confirmation
        });
    }
  };

  // Clear the modal fields and messages
  const clearFields = () => {
    setSelectedClass(null);
    setStatusMessage("");
    setAlertVariant("");
  };

  return (
    <Modal show={show} onHide={() => {
      onHide();
      clearFields(); // Clear the fields when modal is closed
      setConfirmationVisible(false); // Hide confirmation when modal closes
    }}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Classes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Dropdown for selecting a class */}
        <Form.Group>
          <Form.Label>Select Class:</Form.Label>
          <Form.Control
            as="select"
            value={selectedClass || ""}
            onChange={(e) => setSelectedClass(e.target.value)}
          >
            <option value="">Select Class</option>
            {classes.map((cls) => (
              <option key={cls.id} value={cls.id}>
                {cls.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        {/* Delete button with class name */}
        {selectedClass && (
          <Button variant="danger" className="mt-3" onClick={handleDeleteClass}>
            Delete {classes.find(c => c.id === selectedClass)?.name}
          </Button>
        )}

        {/* Confirmation message */}
        {confirmationVisible && (
          <div className="mt-3">
            <Alert variant="warning">
              Are you sure you want to delete the class "
              {classes.find(c => c.id === selectedClass)?.name}"? This action cannot be undone.
            </Alert>
            <Button variant="danger" onClick={confirmDeleteClass}>
              Confirm Delete
            </Button>
            <Button variant="secondary" className="ml-2" onClick={() => setConfirmationVisible(false)}>
              Cancel
            </Button>
          </div>
        )}

        {/* Status message for success or failure */}
        {statusMessage && (
          <Alert variant={alertVariant} className="mt-3">
            {statusMessage}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {
          onHide();
          clearFields(); // Clear fields when modal is closed
        }}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteClassesModal;
