import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { getClassesBySchoolId, getStudentsByClassId, deleteStudent } from "../services/APIClient"; // Import your API calls

function DeleteStudentsModal({ show, onHide, schoolid }) {
  const [classes, setClasses] = useState([]); // Store classes
  const [students, setStudents] = useState([]); // Store students for the selected class
  const [selectedClass, setSelectedClass] = useState(null); // Store the selected class
  const [selectedStudent, setSelectedStudent] = useState(null); // Store the selected student
  const [statusMessage, setStatusMessage] = useState(""); // Status message for success/failure
  const [alertVariant, setAlertVariant] = useState(""); // Alert variant for Bootstrap
  const [confirmationVisible, setConfirmationVisible] = useState(false); // Toggle for confirmation

  // Fetch classes when the modal opens
  useEffect(() => {
    if (schoolid) {
      getClassesBySchoolId(schoolid)
        .then((response) => {
          setClasses(response); // Store the classes
        })
        .catch((error) => {
          setStatusMessage("Error fetching classes. Please try again.");
          setAlertVariant("danger");
        });
    }
  }, [schoolid]);

  // Fetch students when a class is selected
  useEffect(() => {
    if (selectedClass) {
      getStudentsByClassId(selectedClass)
        .then((response) => {
          setStudents(response); // Store the students for the selected class
        })
        .catch((error) => {
          setStatusMessage("Error fetching students. Please try again.");
          setAlertVariant("danger");
        });
    }
  }, [selectedClass]);

  // Handle student deletion confirmation
  const handleDeleteStudent = () => {
    setConfirmationVisible(true); // Show the confirmation step
  };

  // Handle the actual delete API call after confirmation
  const confirmDeleteStudent = () => {
    if (selectedStudent) {
      deleteStudent(selectedStudent)
        .then(() => {
          const studentName = students.find(s => s.id === selectedStudent).name;
          setStatusMessage(`Student "${studentName}" was deleted successfully!`);
          setAlertVariant("success");
          clearFields(); // Clear selection and reset the form
          autoClearMessage(); // Clear the message after a few seconds
        })
        .catch((error) => {
          setStatusMessage("Failed to delete the student. Please try again.");
          setAlertVariant("danger");
          autoClearMessage(); // Clear the message after a few seconds
        })
        .finally(() => {
          setConfirmationVisible(false); // Hide confirmation
        });
    }
  };

  // Automatically clear the status message after a few seconds
  const autoClearMessage = () => {
    setTimeout(() => {
      setStatusMessage("");
      setAlertVariant("");
    }, 5000); // Clear message after 5 seconds
  };

  // Clear the modal fields and messages
  const clearFields = () => {
    setSelectedClass(null);
    setSelectedStudent(null);
    setStudents([]); // Clear the student list when class changes
  };

  return (
    <Modal show={show} onHide={() => {
      onHide();
      clearFields(); // Clear the fields when modal is closed
      setConfirmationVisible(false); // Hide confirmation when modal closes
    }}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Students</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Dropdown for selecting a class */}
        <Form.Group>
          <Form.Label>Select Class:</Form.Label>
          <Form.Control
            as="select"
            value={selectedClass || ""}
            onChange={(e) => setSelectedClass(e.target.value)}
          >
            <option value="">Select Class</option>
            {classes.map((cls) => (
              <option key={cls.id} value={cls.id}>
                {cls.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        {/* Dropdown for selecting a student */}
        {selectedClass && (
          <Form.Group className="mt-3">
            <Form.Label>Select Student:</Form.Label>
            <Form.Control
              as="select"
              value={selectedStudent || ""}
              onChange={(e) => setSelectedStudent(e.target.value)}
            >
              <option value="">Select Student</option>
              {students.map((student) => (
                <option key={student.id} value={student.id}>
                  {student.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}

        {/* Delete button with student name */}
        {selectedStudent && (
          <Button variant="danger" className="mt-3" onClick={handleDeleteStudent}>
            Delete {students.find(s => s.id === selectedStudent)?.name}
          </Button>
        )}

        {/* Confirmation message */}
        {confirmationVisible && (
          <div className="mt-3">
            <Alert variant="warning">
              Are you sure you want to delete student "
              {students.find(s => s.id === selectedStudent)?.name}"? This action cannot be undone.
            </Alert>
            <Button variant="danger" onClick={confirmDeleteStudent}>
              Confirm Delete
            </Button>
            <Button variant="secondary" className="ml-2" onClick={() => setConfirmationVisible(false)}>
              Cancel
            </Button>
          </div>
        )}

        {/* Status message for success or failure */}
        {statusMessage && (
          <Alert variant={alertVariant} className="mt-3" dismissible onClose={() => setStatusMessage("")}>
            {statusMessage}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => {
          onHide();
          clearFields(); // Clear fields when modal is closed
          setStatusMessage(""); // Clear any existing messages when modal is closed
        }}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteStudentsModal;
