import React, { useState, useEffect } from "react";
import HeadingBanner from "../components/HeadingBanner";
import Login from "../components/Login";
import RightPanel from "../components/RightPanel";
import { Container, Row, Col } from "react-bootstrap";
import SignUpForm from "../components/SignUpForm";
import OrientationPrompt from "../components/OrientationPrompt"; 

import "../components/RightPanel.css";

function App(props) {
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  return (
    <Container fluid className="vh-100 d-flex flex-column">
      <HeadingBanner
        isLoggedIn={false}
        cloud={true}
        name={"Assessing Letter & Phonemic Awareness Class Assistant"}
      />
      <Row className="h-100">
          <Login />
        {/* <Col sm={8}>
        </Col>
        <RightPanel
          msg="Don't have an account yet? Check us out on alpaca-assessment.com"
          label="Alpaca Assessment"
          path="https://www.alpaca-assessment.com/"
        /> */}
      </Row>
      {isPortrait && <OrientationPrompt />}{" "}
      {/* Render OrientationPrompt based on isPortrait */}
    </Container>
  );
}

export default App;
